import React from "react"
import { useLocalization } from "gatsby-theme-i18n"
import { navigate } from "gatsby"
import { Layout } from "@bw/layouts"
import { Section, Button } from "@bw/bits"
import { UserConnect } from "@bw/partials"
import { FormField, Input } from "@bw/forms"
import { forgotPassword } from "@actions/user"
import { useUser } from "@contexts/user"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { Formik, Form, Field } from "formik"
import * as yup from "yup"

function ForgotPassword({ pageContext }) {
  const { localizedPath, defaultLang } = useLocalization()
  const { locale } = pageContext
  const [success, setSuccess] = React.useState(false)
  const { t } = useTranslation()
  const [user] = useUser()

  if (user.loggedIn) {
    navigate(
      localizedPath({
        defaultLang,
        locale,
        path: `/user/${user.publicKey}/`,
      })
    )
    return null
  }

  return (
    <Layout {...{ pageContext }}>
      <Section
        title={t("user.form.forgot.title")}
        subtitle={t("user.form.forgot.subtitle")}
      />
      <Section>
        {!user.publicKey ? (
          <UserConnect />
        ) : success ? (
          <p>{t("user.form.forgot.success")}</p>
        ) : (
          <Formik
            validationSchema={yup.object().shape({
              email: yup.string().required().email(),
            })}
            initialValues={{
              email: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              forgotPassword(values.email, locale)
                .then(() => {
                  setSuccess(true)
                })
                .catch(err => {
                  toast.error(err.response.data.message)
                })
                .finally(() => {
                  setSubmitting(false)
                })
            }}
          >
            {({ isSubmitting }) => (
              <Form style={{ width: "316px" }} data-cy="forgot-form">
                <FormField
                  name="email"
                  title={t("user.form.email")}
                  help={t("user.form.weCheckEmailWithAddressX", {
                    address:
                      user.publicKey.slice(0, 4) +
                      "..." +
                      user.publicKey.slice(-4),
                  })}
                >
                  <Field name="email" type="email" component={Input} />
                </FormField>
                <p>
                  <Button
                    submit
                    data-cy="forgot-submit"
                    label={t("user.form.forgot.button")}
                    disabled={isSubmitting}
                  />
                </p>
              </Form>
            )}
          </Formik>
        )}
      </Section>
    </Layout>
  )
}

export default ForgotPassword
